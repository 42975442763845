import { useQuery } from '@tanstack/react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/axiosInstance';

const getStripeKeys = async () => {
  const response = await axios.get(`${ENDPOINT.CARDS}?createIntent=true`); // new endpoint
  delete response?.data?.cards;

  return response?.data;
};

const useStripeKeys = (options = {}) =>
  useQuery({ queryKey: ['stripeKeys'], queryFn: getStripeKeys, ...options });

export default useStripeKeys;
