import { useQuery } from '@tanstack/react-query';
import type { HydraCollection } from 'types/HydraCollection';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/axiosInstance';

type Response = HydraCollection<{
  '@id': string;
  '@type': 'PaymentCard';
  expired: boolean;
  id: string;
  index: string;
  issuer: string;
  mask: string;
  provider: string;
  validatedByProvider: boolean;
  validityMonth: number;
  validityYear: number;
}>;

const getPaymentCards = async () => {
  const { data } = await axios.get<Response>(ENDPOINT.PAYMENT_CARDS);

  return data['hydra:member'];
};

const usePaymentCards = (options?: { enabled?: boolean }) =>
  useQuery({
    queryKey: ['paymentCards'],
    queryFn: getPaymentCards,
    ...options,
  });
export default usePaymentCards;
