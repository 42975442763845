import isEmpty from 'lodash/isEmpty';

import cardPaymentTypes from '@constants/cardPaymentTypes';
import PAYMENT_TYPES from '@constants/paymentTypes';
import useBasketMethods from '@hooks/useBasketMethods';
import usePaymentCards from '@hooks/usePaymentCards';
import checkIfPaymentTypesIncludesCard from '@utils/checkIfPaymentTypesIncludesCard';

const useCheckIsDisabled = () => {
  const {
    basketQuery: {
      data: {
        paymentType,
        paymentTypes = {},
        paymentMetadata = {},
        price = {},
      } = {},
    },
    basketStore: { blik },
  } = useBasketMethods();

  const { data: cards = [] } = usePaymentCards({
    enabled: checkIfPaymentTypesIncludesCard(Object.values(paymentTypes)),
  });

  const isSelectedProviderCardAvaliable = cardPaymentTypes.includes(paymentType)
    ? !isEmpty(cards?.find(({ provider }) => provider === paymentType))
    : true;

  const checkIsDisabled = () => {
    let isDisabled =
      isEmpty(paymentType) ||
      isEmpty(paymentTypes) ||
      !isSelectedProviderCardAvaliable ||
      !Object.values(paymentTypes).includes(paymentType);

    if ([PAYMENT_TYPES.PAYU_GOOGLE_PAY].includes(paymentType)) {
      return false;
    }

    if ([PAYMENT_TYPES.BLUE_MEDIA_CARD].includes(paymentType)) {
      return false;
    }

    if ([PAYMENT_TYPES.PAYU_GOOGLE_PAY].includes(paymentType)) {
      return true;
    }

    if (price.afterDiscount <= 0) {
      return false;
    }

    if (
      [PAYMENT_TYPES.BLUE_MEDIA_BLIK, PAYMENT_TYPES.PAYU_BLIK].includes(
        paymentType
      )
    ) {
      return isDisabled || blik.replace(/_/g, '').replace(/ /g, '').length < 6;
    }

    if (
      [PAYMENT_TYPES.PAYPO, PAYMENT_TYPES.PAYU_PAYPO].includes(paymentType) &&
      !paymentMetadata.paypoAddressPostCode
    ) {
      return true;
    }

    return isDisabled;
  };

  return checkIsDisabled();
};

export default useCheckIsDisabled;
