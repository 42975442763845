import { type ComponentType, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '@store/auth/auth.slice';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import type { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import {
  selectModuleStripe,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import useStripeKeys from '@hooks/useStripeKeys';

const withStripeConsumer = <P extends object>(Component: ComponentType<P>) => {
  const StripeConsumer = (props: P) => {
    const [stripe, setStripe] = useState<Stripe | null>(null);
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const { cardPayments: isStripeCardPayments } =
      useAppConfigSelector(selectModuleStripe);

    const { data: { secret = null, key = null } = {} } = useStripeKeys({
      enabled: isStripeCardPayments && isAuthenticated,
    });

    useEffect(() => {
      if (key !== null) {
        loadStripe(key).then(stripe => setStripe(stripe));
      }
    }, [key]);

    if (!isStripeCardPayments) {
      return <Component {...props} />;
    }

    return (
      <Elements stripe={stripe}>
        <ElementsConsumer>
          {({ stripe, elements }) => (
            <Component
              {...props}
              stripe={stripe}
              elements={elements}
              stripeSecret={secret}
            />
          )}
        </ElementsConsumer>
      </Elements>
    );
  };

  StripeConsumer.displayName = 'StripeConsumer';
  return StripeConsumer;
};

withStripeConsumer.displayName = 'withStripeConsumer';

export default withStripeConsumer;
