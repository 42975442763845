import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { useDispatch } from 'react-redux';

import PAYMENT_TYPES from '@constants/paymentTypes';
import ROUTE_URLS from '@constants/routeUrls';
import useCheckIsPayButtonDisabled from '@hooks/basket/useCheckIsPayButtonDisabled';
import {
  selectBranding,
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import useBasketMethods from '@hooks/useBasketMethods';
import useCreateBasketOrder from '@hooks/useCreateBasketOrder';
import BasketService from '@services/Basket.service';
import { pushBeginPurchaseGTMEvent } from '@utils/gtm';
import { checkIsUrlExternal } from '@utils/helpers';
import { checkIfStandardPaymentTypesAvailable } from '@utils/paymentTypesHelpers';
import showToast from '@utils/showToast';

const usePaymentSection = ({ stripe }) => {
  const { t, i18n } = useTranslation();

  const router = useRouter();
  const dispatch = useDispatch();

  const {
    basketQuery: {
      data: {
        id: basketId,
        paymentType,
        paymentTypes = [],
        discountCode,
        useDiscountCode,
        price = {},
        rows,
      } = {},
    },
    basketStore: { blik, invoice, useInvoice, useMoneyBox },
    basketCommonCatch,
    setInvoice,
    setPaymentMethod,
    setPayStatus,
    setUseMoneyBox,
    updateOrderBasketModify,
  } = useBasketMethods();

  const isBasketPayButtonAvailable =
    checkIfStandardPaymentTypesAvailable(paymentTypes);
  const {
    currencySymbol,
    defaultRegion: { currencyCode },
  } = useAppConfigSelector(selectMultinational);
  const { name: brandName } = useAppConfigSelector(selectBranding);

  const handleClickPaymentType = ({ paymentType, metadata = {} }) => {
    updateOrderBasketModify({
      payload: {
        paymentType,
        paymentMetadata: metadata,
        ...(useDiscountCode ? { discountCode: discountCode?.['@id'] } : {}),
      },
    });

    dispatch(setPaymentMethod(paymentType));
  };

  const {
    isLoading: isLoadingCreateBasketOrder,
    mutateAsync: createBasketOrder,
  } = useCreateBasketOrder();

  const handleBeginPurchaseGtmEvent = (paymentType, transactionId) => {
    pushBeginPurchaseGTMEvent({
      brandName,
      currencyCode,
      discountCode: discountCode?.code || '',
      items: rows || [],
      price: price?.afterDiscount || 0,
      paymentType,
      shippingCost: price?.priceParts?.DELIVERY,
      transactionId: transactionId ?? basketId,
    });
  };

  const handlePay = ({ authorizationCode } = {}) => {
    const convertedInvoice = BasketService.convertInvoice(invoice);
    const { protocol, hostname, port } = window.location;
    const host = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
    const returnUrl = `${host}/${i18n.language}/new-order/{{id}}/payment-status?type=${paymentType}`;

    createBasketOrder({
      id: basketId,
      payload: {
        invoice: { include: useInvoice, ...convertedInvoice },
        returnUrl,
        authorizationCode,
        ...([PAYMENT_TYPES.BLUE_MEDIA_BLIK, PAYMENT_TYPES.PAYU_BLIK].includes(
          paymentType
        )
          ? { blik: parseInt(blik.replace(/_/g, '').replace(/ /g, '')) }
          : {}),
      },
    })
      .then(async response => {
        if (paymentType !== PAYMENT_TYPES.PAYU_GOOGLE_PAY) {
          handleBeginPurchaseGtmEvent(paymentType, response.orderId);
        }

        if (response?.declineCode === 'authentication_required') {
          try {
            const { error, paymentIntent } = await stripe.confirmCardPayment(
              response.clientSecret,
              {
                payment_method: response.lastPaymentMethodId,
              }
            );

            if (error) {
              showToast(
                /* i18next-extract-disable-next-line */
                t(`$*error.stripe.${error.code}`, `${error.message}`),
                {
                  type: 'error',
                }
              );
              return router.push(ROUTE_URLS.NEW_ORDER_ERROR);
            } else {
              if (paymentIntent.status === 'succeeded') {
                showToast(
                  `${t(
                    '$*success.stripe.payment_intent_authentication_success',
                    'Uwierzytelnianie Twojej metody płatności przebiegło pomyślnie!'
                  )}
                  ${t(
                    '$*success.stripe.withdrawFunds',
                    'Środki zostały pobrane z twojego konta!'
                  )}`,
                  {
                    type: 'success',
                  }
                );
                return router.push(
                  ROUTE_URLS.NEW_ORDER_PAYMENT_STATUS(
                    response?.order?.orderId ?? 0
                  )
                );
              }
            }
          } catch (error) {
            //
          }
        }

        if (
          [
            PAYMENT_TYPES.CASH,
            PAYMENT_TYPES.BANK_WIRE,
            PAYMENT_TYPES.STRIPE_CARD,
          ].includes(response?.paymentType) ||
          response?.status?.systemValue === 'PAID'
        ) {
          dispatch(
            setPayStatus({ isPayActionSuccess: true, isExternalPayment: false })
          );

          return router.push(
            ROUTE_URLS.NEW_ORDER_PAYMENT_STATUS(response?.orderId ?? 0)
          );
        }

        showToast(
          t(
            '$*notification.paymentRedirect.info',
            'Za chwilę zostaniesz przekierowany na stronę płatności'
          ),
          { type: 'info' }
        );

        const redirectUrl =
          response?.paymentLink ??
          ROUTE_URLS.NEW_ORDER_PAYMENT_STATUS(response?.orderId ?? 0);

        dispatch(
          setPayStatus({
            isPayActionSuccess: true,
            isExternalPayment: checkIsUrlExternal(redirectUrl),
          })
        );

        return router.push(redirectUrl);
      })
      .catch(async error => {
        await dispatch(
          setPayStatus({
            isPayActionSuccess: false,
            isExternalPayment: false,
          })
        );

        basketCommonCatch({ data: error?.response?.data });
      });
  };

  const isDisabled = useCheckIsPayButtonDisabled();

  return {
    price,
    invoice,
    handlePay,
    useInvoice,
    isDisabled,
    setInvoice,
    useMoneyBox,
    paymentType,
    paymentTypes,
    setUseMoneyBox,
    currencySymbol,
    handleClickPaymentType,
    updateOrderBasketModify,
    isLoadingCreateBasketOrder,
    isBasketPayButtonAvailable,
    handleBeginPurchaseGtmEvent,
  };
};

export default usePaymentSection;
