import type { PaymentType } from '@tsTypes/PaymentType';

import PAYMENT_TYPES from '@constants/paymentTypes';

export const checkIfStandardPaymentTypesAvailable = (
  paymentTypes: PaymentType[]
) =>
  paymentTypes.some(
    paymentType => paymentType !== PAYMENT_TYPES.PAYU_GOOGLE_PAY
  );
